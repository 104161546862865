/* Hide Placeholder */
.editor-hidePlaceholder .public-DraftEditorPlaceholder-root{
    display: none;
}
/* min height for content editor */
.full-editor .editor .public-DraftEditor-content {
  max-width: "100%";
  box-sizing: border-box; 
}

.read-only-content .editor .public-DraftEditor-content {
}

.full-editor img {

}
